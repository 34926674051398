// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-admin-addadmin-jsx": () => import("./../../../src/pages/admin/addadmin.jsx" /* webpackChunkName: "component---src-pages-admin-addadmin-jsx" */),
  "component---src-pages-admin-addproject-jsx": () => import("./../../../src/pages/admin/addproject.jsx" /* webpackChunkName: "component---src-pages-admin-addproject-jsx" */),
  "component---src-pages-admin-addrecentplantation-jsx": () => import("./../../../src/pages/admin/addrecentplantation.jsx" /* webpackChunkName: "component---src-pages-admin-addrecentplantation-jsx" */),
  "component---src-pages-admin-addspecies-jsx": () => import("./../../../src/pages/admin/addspecies.jsx" /* webpackChunkName: "component---src-pages-admin-addspecies-jsx" */),
  "component---src-pages-admin-admindashboardstatus-jsx": () => import("./../../../src/pages/admin/admindashboardstatus.jsx" /* webpackChunkName: "component---src-pages-admin-admindashboardstatus-jsx" */),
  "component---src-pages-admin-adminedit-jsx": () => import("./../../../src/pages/admin/adminedit.jsx" /* webpackChunkName: "component---src-pages-admin-adminedit-jsx" */),
  "component---src-pages-admin-adminstatus-jsx": () => import("./../../../src/pages/admin/adminstatus.jsx" /* webpackChunkName: "component---src-pages-admin-adminstatus-jsx" */),
  "component---src-pages-admin-adminview-jsx": () => import("./../../../src/pages/admin/adminview.jsx" /* webpackChunkName: "component---src-pages-admin-adminview-jsx" */),
  "component---src-pages-admin-dashboardview-jsx": () => import("./../../../src/pages/admin/dashboardview.jsx" /* webpackChunkName: "component---src-pages-admin-dashboardview-jsx" */),
  "component---src-pages-admin-donorlist-jsx": () => import("./../../../src/pages/admin/donorlist.jsx" /* webpackChunkName: "component---src-pages-admin-donorlist-jsx" */),
  "component---src-pages-admin-donorpopup-jsx": () => import("./../../../src/pages/admin/donorpopup.jsx" /* webpackChunkName: "component---src-pages-admin-donorpopup-jsx" */),
  "component---src-pages-admin-donorsview-jsx": () => import("./../../../src/pages/admin/donorsview.jsx" /* webpackChunkName: "component---src-pages-admin-donorsview-jsx" */),
  "component---src-pages-admin-greenassetsstatus-jsx": () => import("./../../../src/pages/admin/greenassetsstatus.jsx" /* webpackChunkName: "component---src-pages-admin-greenassetsstatus-jsx" */),
  "component---src-pages-admin-initiativeeditpage-jsx": () => import("./../../../src/pages/admin/initiativeeditpage.jsx" /* webpackChunkName: "component---src-pages-admin-initiativeeditpage-jsx" */),
  "component---src-pages-admin-initiativeview-jsx": () => import("./../../../src/pages/admin/initiativeview.jsx" /* webpackChunkName: "component---src-pages-admin-initiativeview-jsx" */),
  "component---src-pages-admin-jsx": () => import("./../../../src/pages/admin.jsx" /* webpackChunkName: "component---src-pages-admin-jsx" */),
  "component---src-pages-admin-projectedit-jsx": () => import("./../../../src/pages/admin/projectedit.jsx" /* webpackChunkName: "component---src-pages-admin-projectedit-jsx" */),
  "component---src-pages-admin-projectstatus-jsx": () => import("./../../../src/pages/admin/projectstatus.jsx" /* webpackChunkName: "component---src-pages-admin-projectstatus-jsx" */),
  "component---src-pages-admin-recentplantationedit-jsx": () => import("./../../../src/pages/admin/recentplantationedit.jsx" /* webpackChunkName: "component---src-pages-admin-recentplantationedit-jsx" */),
  "component---src-pages-admin-recentplantationstatus-jsx": () => import("./../../../src/pages/admin/recentplantationstatus.jsx" /* webpackChunkName: "component---src-pages-admin-recentplantationstatus-jsx" */),
  "component---src-pages-admin-sidebar-jsx": () => import("./../../../src/pages/admin/sidebar.jsx" /* webpackChunkName: "component---src-pages-admin-sidebar-jsx" */),
  "component---src-pages-admin-speciesedit-jsx": () => import("./../../../src/pages/admin/speciesedit.jsx" /* webpackChunkName: "component---src-pages-admin-speciesedit-jsx" */),
  "component---src-pages-admin-speciesstatus-jsx": () => import("./../../../src/pages/admin/speciesstatus.jsx" /* webpackChunkName: "component---src-pages-admin-speciesstatus-jsx" */),
  "component---src-pages-admin-upload-green-asset-kml-jsx": () => import("./../../../src/pages/admin/uploadGreenAssetKml.jsx" /* webpackChunkName: "component---src-pages-admin-upload-green-asset-kml-jsx" */),
  "component---src-pages-admin-uploadgreenasset-jsx": () => import("./../../../src/pages/admin/uploadgreenasset.jsx" /* webpackChunkName: "component---src-pages-admin-uploadgreenasset-jsx" */),
  "component---src-pages-admin-uploadplantation-jsx": () => import("./../../../src/pages/admin/uploadplantation.jsx" /* webpackChunkName: "component---src-pages-admin-uploadplantation-jsx" */),
  "component---src-pages-admin-viewgreenasset-jsx": () => import("./../../../src/pages/admin/viewgreenasset.jsx" /* webpackChunkName: "component---src-pages-admin-viewgreenasset-jsx" */),
  "component---src-pages-bgsw-bgswreport-jsx": () => import("./../../../src/pages/bgsw/bgswreport.jsx" /* webpackChunkName: "component---src-pages-bgsw-bgswreport-jsx" */),
  "component---src-pages-bgsw-index-jsx": () => import("./../../../src/pages/bgsw/index.jsx" /* webpackChunkName: "component---src-pages-bgsw-index-jsx" */),
  "component---src-pages-chikka-timmasandra-jsx": () => import("./../../../src/pages/chikka_timmasandra.jsx" /* webpackChunkName: "component---src-pages-chikka-timmasandra-jsx" */),
  "component---src-pages-coimbatore-jsx": () => import("./../../../src/pages/coimbatore.jsx" /* webpackChunkName: "component---src-pages-coimbatore-jsx" */),
  "component---src-pages-donation-success-jsx": () => import("./../../../src/pages/donation/success.jsx" /* webpackChunkName: "component---src-pages-donation-success-jsx" */),
  "component---src-pages-donations-gifting-corporate-jsx": () => import("./../../../src/pages/donations-gifting-corporate.jsx" /* webpackChunkName: "component---src-pages-donations-gifting-corporate-jsx" */),
  "component---src-pages-donations-gifting-jsx": () => import("./../../../src/pages/donations-gifting.jsx" /* webpackChunkName: "component---src-pages-donations-gifting-jsx" */),
  "component---src-pages-edit-sapling-jsx": () => import("./../../../src/pages/edit-sapling.jsx" /* webpackChunkName: "component---src-pages-edit-sapling-jsx" */),
  "component---src-pages-failure-jsx": () => import("./../../../src/pages/failure.jsx" /* webpackChunkName: "component---src-pages-failure-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../../../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-forestdonationwidget-jsx": () => import("./../../../src/pages/forestdonationwidget.jsx" /* webpackChunkName: "component---src-pages-forestdonationwidget-jsx" */),
  "component---src-pages-forests-detail-bengaluru-forest-jsx": () => import("./../../../src/pages/forests-detail/bengaluru-forest.jsx" /* webpackChunkName: "component---src-pages-forests-detail-bengaluru-forest-jsx" */),
  "component---src-pages-forests-detail-coimbatore-forest-jsx": () => import("./../../../src/pages/forests-detail/coimbatore-forest.jsx" /* webpackChunkName: "component---src-pages-forests-detail-coimbatore-forest-jsx" */),
  "component---src-pages-forests-detail-hyderabad-forest-jsx": () => import("./../../../src/pages/forests-detail/hyderabad-forest.jsx" /* webpackChunkName: "component---src-pages-forests-detail-hyderabad-forest-jsx" */),
  "component---src-pages-forests-detail-pune-forest-jsx": () => import("./../../../src/pages/forests-detail/pune-forest.jsx" /* webpackChunkName: "component---src-pages-forests-detail-pune-forest-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-gifting-success-jsx": () => import("./../../../src/pages/gifting/success.jsx" /* webpackChunkName: "component---src-pages-gifting-success-jsx" */),
  "component---src-pages-hccb-jsx": () => import("./../../../src/pages/hccb.jsx" /* webpackChunkName: "component---src-pages-hccb-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-initiative-card-jsx": () => import("./../../../src/pages/initiative-card.jsx" /* webpackChunkName: "component---src-pages-initiative-card-jsx" */),
  "component---src-pages-initiative-edit-view-jsx": () => import("./../../../src/pages/initiative-edit-view.jsx" /* webpackChunkName: "component---src-pages-initiative-edit-view-jsx" */),
  "component---src-pages-initiative-list-jsx": () => import("./../../../src/pages/initiative-list.jsx" /* webpackChunkName: "component---src-pages-initiative-list-jsx" */),
  "component---src-pages-map-initiative-jsx": () => import("./../../../src/pages/map-initiative.jsx" /* webpackChunkName: "component---src-pages-map-initiative-jsx" */),
  "component---src-pages-mhadocument-jsx": () => import("./../../../src/pages/mhadocument.jsx" /* webpackChunkName: "component---src-pages-mhadocument-jsx" */),
  "component---src-pages-nishtasbirthday-jsx": () => import("./../../../src/pages/nishtasbirthday.jsx" /* webpackChunkName: "component---src-pages-nishtasbirthday-jsx" */),
  "component---src-pages-ourteam-jsx": () => import("./../../../src/pages/ourteam.jsx" /* webpackChunkName: "component---src-pages-ourteam-jsx" */),
  "component---src-pages-paypal-jsx": () => import("./../../../src/pages/paypal.jsx" /* webpackChunkName: "component---src-pages-paypal-jsx" */),
  "component---src-pages-plantation-grid-jsx": () => import("./../../../src/pages/plantation-grid.jsx" /* webpackChunkName: "component---src-pages-plantation-grid-jsx" */),
  "component---src-pages-plantationregister-jsx": () => import("./../../../src/pages/plantationregister.jsx" /* webpackChunkName: "component---src-pages-plantationregister-jsx" */),
  "component---src-pages-planter-details-jsx": () => import("./../../../src/pages/planter-details.jsx" /* webpackChunkName: "component---src-pages-planter-details-jsx" */),
  "component---src-pages-refundpolicy-jsx": () => import("./../../../src/pages/refundpolicy.jsx" /* webpackChunkName: "component---src-pages-refundpolicy-jsx" */),
  "component---src-pages-registerplantation-jsx": () => import("./../../../src/pages/registerplantation.jsx" /* webpackChunkName: "component---src-pages-registerplantation-jsx" */),
  "component---src-pages-registeryour-jsx": () => import("./../../../src/pages/registeryour.jsx" /* webpackChunkName: "component---src-pages-registeryour-jsx" */),
  "component---src-pages-registeryour-plantation-jsx": () => import("./../../../src/pages/registeryour-plantation.jsx" /* webpackChunkName: "component---src-pages-registeryour-plantation-jsx" */),
  "component---src-pages-special-donation-jsx": () => import("./../../../src/pages/special-donation.jsx" /* webpackChunkName: "component---src-pages-special-donation-jsx" */),
  "component---src-pages-species-jsx": () => import("./../../../src/pages/species.jsx" /* webpackChunkName: "component---src-pages-species-jsx" */),
  "component---src-pages-track-your-forests-jsx": () => import("./../../../src/pages/track-your-forests.jsx" /* webpackChunkName: "component---src-pages-track-your-forests-jsx" */),
  "component---src-pages-tracka-sapling-jsx": () => import("./../../../src/pages/trackaSapling.jsx" /* webpackChunkName: "component---src-pages-tracka-sapling-jsx" */),
  "component---src-pages-view-sapling-jsx": () => import("./../../../src/pages/view-sapling.jsx" /* webpackChunkName: "component---src-pages-view-sapling-jsx" */),
  "component---src-pages-zscaler-jsx": () => import("./../../../src/pages/zscaler.jsx" /* webpackChunkName: "component---src-pages-zscaler-jsx" */)
}

