import { createStore } from 'redux';

// reducer
import rootReducer from '../store/reducers/index';

let appStore;

export default (preloadedState) => {
  appStore = createStore(rootReducer, preloadedState);
  return appStore;
};

export { appStore };
